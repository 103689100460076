<!-- eslint-disable vue/no-deprecated-destroyed-lifecycle -->
<!-- eslint-disable vue/require-v-for-key -->
`<template>
    <div @click="hide()" class="w-screen ">
        <br><br>
        <div>
            <div class="w-screen  flex justify-center items-start carousel bg-white py-2 ">
                <!-- <div class="carousel-item w-screen"> -->
                <div class="w-11/12 flex max-lg:flex-col-reverse justify-between items-center">
                    <div class="max-lg:w-full lg:w-1/2 flex justify-start items-center">
                        <div class="flex justify-start items-center h-full">
                            <div class="h-fit max-lg:pt-7">

                                <p class="text-left max-md:text-sm text-gray-600 max-lg:text-justify">Bienvenue sur
                                    notre plateforme
                                    dédiée
                                    à
                                    l'information agricole, où les données les plus récentes se combinent à des analyses
                                    approfondies pour éclairer vos décisions et optimiser vos rendements. <br> Nous
                                    permettons de fournir
                                    des divers informations agricoles et des conseils pratiques sur ce domaine. <br>
                                    <br>
                                </p>
                                <div
                                    class="w-full flex items-center max-md:justify-between max-lg:justify-center lg:justify-start gap-x-2">

                                    <button
                                        class="px-4 h-9 max-md:w-1/2   border-2 border-green-600 text-green-600 hover:border-blue-400 hover:text-white hover:bg-blue-400"
                                        @click="$router.push('/Client/Inscription')">S'inscrire</button>

                                    <button class="px-4 h-9 max-md:w-1/2  text-white bg-green-600 hover:bg-green-500"
                                        @click="$router.push('/Client/Connection')">Se connecter</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="max-lg:w-full lg:w-1/2 max-lg:flex max-lg:justify-center max-lg:items-center">
                        <div class="w-full">
                            <img src="./../assets/img.jpeg " alt="image panel" class="clip-path-polygon max-lg:hidden">
                            <img src="./../assets/img.jpeg " alt="image panel" class="lg:hidden ">
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <br><br>

        <div class="w-screen flex justify-center items-center bg-slate-50">
            <Service></Service>
        </div>
        <br>
        <div class="w-screen flex justify-center items-center">
            <Propos></Propos>
        </div>
        <br>
        <hr>
        <br>
        <div id="Pricing" class="overflow-x-auto">
            <h2 class="text-xl sm:text-3xl  font-semibold text-green-600">Abonnement</h2>
            <p class="w-full px-5">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eum debitis sequi
                cupiditate in. Possimus
                obcaecati consectetur accusamus animi nostrum maiores Lorem ipsum dolor sit amet, consectetur
                adipisicing elit. Odio totam laboriosam explicabo nisi qui ducimus sed, non praesentium ratione quos a
                accusamus voluptatibus dolorum! Eius hic nihil sit maiores iure.</p>
            <Pricing></Pricing>
        </div>
        <br>
        <hr>
        <br>
        <div class="flex justify-center items-center w-screen bg-slate-50 py-2" id="Contact">
            <Message></Message>
        </div>

    </div>
    <!-- </div> -->
    <!--modal menu mobile-->
    <div class="modal_header transition-all bg-white flex justify-center  duration-500 w-screen h-fit   overflow-hidden"
        ref="modal_menu">
        <div class="w-11/12">
            <div class="flex justify-between max-lg:py-2  h-fit overflow-hidden text-sm sm:text-base items-center">
                <div class="flex justify-start items-center gap-x-4">
                    <router-link to="/">
                        <img src="./img/logovf.png" class="max-lg:w-9 lg:w-7 ">
                    </router-link>
                    <span class="font-bold text-lg text-slate-800 max-md:pt-3 md:pt-2">
                        AGRIBUSINESS
                    </span>
                </div>
                <div class="flex justify-end items-center gap-x-4">
                    <div class="max-lg:hidden   overflow-hidden h-fit">
                        <div class="flex gap-x-4 overflow-hidden  max-sm:text-sm items-center justify-between h-fit">
                            <div v-for="(section, index) in sections" :key="index" class="px-3 h-fit">
                                <a class=" overflow-hidden  no-underline text-gray-800  h-fit  transition duration-500text-lg hover:text-blue-500"
                                    :href="`#${section.id}`" :class="{ active: section.isActive }">{{ section.title
                                    }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="flex  items-center justify-end">
                        <div class="max-lg:hidden">
                            <router-link to="/Client/Connection"><button
                                    class="bg-green-600 py-1 px-4 hover:bg-green-500 text-white my-3 mx-2">Se
                                    connecter</button></router-link>
                        </div>
                        <div class=" lg:hidden flex justify-center items-center">
                            <button @click="show()" ref="btn_menu"
                                class="active:bg-gray-200 fas text-black w-7 fa-bars fa-2x transition-all   duration-500"
                                v-show="!modal_menu"></button>
                            <button @click="show()" ref="btn_menu"
                                class="active:bg-gray-200 fas text-black fa-times w-7 fa-2x transition-all   duration-500"
                                v-show="modal_menu"></button>
                        </div>
                    </div>
                </div>
            </div>
            <Transition>
                <div v-show="modal_menu" class=" bg-slate-800 z-10 w-12/12 overflow-hidden">
                    <ul class=" divide-y  flex flex-col items-center justify-start gap-y-3  w-11/12">
                        <li v-for="item in sections" class="divide-y  text-left py-2 w-full">
                            <a :href="item.link" class=" no-underline  text-white ">{{ item.title }}</a>
                        </li>
                        <div class="w-full flex justify-between items-center gap-x-2 py-2">
                            <router-link to="/Client/Insciption" class="w-1/2"><button
                                    class="h-8 border w-full border-white text-white ">S'inscrire</button></router-link>
                            <router-link to="/Client/Connection" class="w-1/2"><button
                                    class="h-8 bg-green-600 w-full hover:bg-green-500 text-white ">Se
                                    connecter</button></router-link>
                        </div>
                    </ul>
                </div>
            </Transition>
        </div>
    </div>

</template>
<style scoped>
.v-enter-active,
.v-leave-active {
    transition: transform 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    transform: translateX(800px);
}

.clip-path-polygon {
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%);
}

.bg-url {
    background: url('./../assets/pexels-pixabay-265278.jpg');
}

.carousel {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.carousel-container {
    display: flex;
    transition: transform 0.5s ease;
}

img {
    max-width: 100%;
    height: auto;
}

button {
    cursor: pointer;
}

* {
    overflow-x: hidden;
}

.trans {
    transition: all 500ms ease-in-out;
}

.div_front {
    position: absolute;
    z-index: 3;
}
</style>
<script>
//import { RouterLink } from 'vue-router';
import '@/components/style/header.css';
import '@/components/style/bodyAccueil.css';
import '@/components/style/all.css';
import Message from '@/components/Message.vue'
import Pricing from './Pricing.vue';
// import Propos from '../../components/Propos.vue'
import Propos from '@/components/Propos.vue'
import Service from '@/components/Service.vue'
//import $ from 'jquery';
export default {
    components: {
        Pricing, Message, Propos, Service
    },
    data() {
        return {
            height: '0',

            modal_pricing_1: false,
            modal_pricing_2: false,
            modalValidation: false,
            modal_pricing_3: false,
            menu_mobile: false,
            observer: null,
            modal_connection: false,
            modal_back: false,
            btn_connection: true,
            inp_password: '',
            show_password: false,
            overflow: {
                overflow: 'scroll',
            },
            modal_menu: false,
            sections: [{
                id: 'Service',
                title: 'Service',
                link: '#Service'
            },
            {
                id: 'Propos',
                title: 'A propos',
                link: '#Propos'
            },
            {
                id: 'Pricing',
                title: 'Abonnement',
                link: '#Pricing'
            },
            {
                id: 'Contact',
                title: 'Contact',
                link: '#Contact'
            },
                // Add more sections as needed
            ],
            isActive: false,
            currentIndex: 0,
            items: []
        }
    },
    mounted() {
        //            this.items = $('.carousel').children();
        //            this.currentIndex = 0;
        // setInterval(() => {
        //     this.items.eq(this.currentIndex).css('display', 'none');
        //     this.currentIndex++;
        //     if (this.currentIndex >= this.items.length) {
        //         this.currentIndex = 0;
        //     }
        //     this.items.eq(this.currentIndex).css('display', 'block');
        // }, 2000);
        window.addEventListener("scroll", () => {
            // Get the current scroll position
            const scrollY = window.scrollY;
            if (this.$refs.modal_menu !== null || this.$refs.btn_menu !== null) {
                // Check if the scroll has reached the bottom
                if (scrollY < 70) {
                    // Add 'bg-opacity-0' class to the element with 'modal_menu' ref
                    //   this.$refs.modal_menu.classList.add('bg-opacity-0');
                    //   this.$refs.modal_menu.classList.remove('bg-slate-50');
                    this.$refs.modal_menu.classList.remove('shadow-sm');
                    this.$refs.modal_menu.classList.remove('shadow-gray-300');
                    this.$refs.btn_menu.classList.remove('border-black');
                    this.$refs.btn_menu.classList.add('border-white');
                    this.$refs.modal_menu.classList.add('text-white');
                    this.$refs.modal_menu.classList.add('border-white');
                    this.$refs.modal_menu.classList.add('shadow-transparent');
                } else {
                    // Handle non-bottom scroll position
                    //console.log('Scroll position:', scrollY);
                    this.$refs.modal_menu.classList.remove('text-white');
                    this.$refs.modal_menu.classList.remove('border-white');
                    this.$refs.modal_menu.classList.remove('shadow-transparent');
                    this.$refs.btn_menu.classList.remove('border-white');
                    //   this.$refs.modal_menu.classList.remove('bg-opacity-0');
                    this.$refs.btn_menu.classList.add('border-black');
                    this.$refs.modal_menu.classList.add('shadow-sm');
                    this.$refs.modal_menu.classList.add('shadow-gray-300');
                    //   this.$refs.modal_menu.classList.add('bg-slate-50');
                }
            }
        });
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {

        modal_connection_show: function () {
            if (this.modal_connection) {
                this.modal_connection = false
                this.modal_back = false
            } else {
                this.modal_connection = true
                this.modal_back = true
            }
        },
        togglePassword: function () {
            this.show_password = !this.show_password
        },
        menu_mobile_show: function () {
            this.menu_mobile = !this.menu_mobile
            this.isActive = !this.isActive
        },
        menu: function () {
            //this.$emit('menu_mobile_show',!this.menu_mobile)
            if (this.height !== '0') {
                this.height = '0'
            } else {
                this.height = 'fit-content'
            }
        },
        hide: function () {
            if (this.modal_menu == true) {
                this.modal_menu = false
            }
        },
        show: function () {
            if (this.modal_menu == false) {
                this.modal_menu = true
            } else {
                this.modal_menu = false
            }
        }
    }
}
</script>
