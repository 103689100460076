<template>
    <br>
    <br>
    <!-- footer pour md+ -->
    <div class="w-screen flex justify-center bg-slate-800 py-3 items-center">
        <div class=" w-11/12 text-white  bottom-0  ">
            <div class=" flex flex-col">

                <div class="flex w-full max-md:flex-col text-left max-md:text-center md:justify-between items-start gap-9">
                    <!-- <h4 class="font-bold text-lg">Contacter nous</h4> -->
                    <div class="max-md:w-full">
                        <h6 class="font-bold">Email</h6>
                        <p class=" text-sm text-gray-300 ">info@haisoa.com</p>
                    </div>
                    <div class="max-md:w-full">
                        <h6 class="font-bold">Telephone</h6>
                        <p class=" text-sm  text-gray-300">+261 33 70 879 64</p>
                        <p class=" text-sm text-gray-300"> +261389875472 </p>
                    </div>
                    <div class="max-md:w-full">
                        <h6 class="font-bold">Adresse</h6>
                        <p class="text-sm  text-gray-300">Lot VK 17 à Fenomanana Mahazoarivo Antananarivo</p>
                    </div>
                </div>

            </div>
            <div class="max-md:flex md:grid md:grid-cols-3 items-center justify-center">
                <div class="max-md:hidden">
                    <hr>
                </div>
                <!--logo reseaux-->
                <div class="flex flex-col max-md:w-full justify-center items-center">
                    <div>
                        <p>Suivez nous</p>
                    </div>

                    <div class="flex gap-5 justify-center items-center ">

                        <div class="flex gap-7 justify-center items-center">
                            <div class="fab fa-facebook fa-2x cursor-pointer"></div>
                            <div class="fab fa-twitter-square fa-2x cursor-pointer"></div>
                            <div class="fab fa-instagram fa-2x cursor-pointer"></div>
                        </div>

                    </div>
                </div>
                <div class="max-md:hidden">
                    <hr>
                </div>
            </div>
            <br>
            <div>
                <p class="text-xs sm:text-base"><router-link to="./Admin/ClientAdmin"
                        style="text-decoration: none; color: inherit;">ⓒ</router-link> Copyright <a
                        href="http://www.haisoa.com" class="text-green-600">HAISOA</a> 2023. Tous droits réservés.</p>
                <div>

                </div>
            </div>
        </div>
    </div>
    <!-- footer pour mobile -->



    <!--modal up-->
</template>

<script>
import '@/components/style/footer.css'
export default {
    data() {
        return {

        }
    }
}
</script>