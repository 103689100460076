<template>
<div class="">
  <br>
  <BodyAccueil></BodyAccueil>
<Footer></Footer>
</div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import BodyAccueil from '@/components/BodyAccueil.vue'

export default {
  name: 'Accueil',
  components: {
    Footer,
    BodyAccueil
  }
}
</script>
